@import "../../../../media-query-helper.scss";

#registerMasterUserPage {
  &.register__master__user {
    min-height: calc(100vh - 184px);
    @include media(">=xl") {
      padding: 0px 160px;
    }
    @include media("<xl") {
      padding: 0px 40px;
    }
    display: flex;
    flex-direction: column;

    .error__message {
      text-align: -webkit-center;
    }
    .card {
      font-family: 'Dosis';
      box-sizing: border-box;
      min-height: 652px;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 70px 240px 125px;
      @include media("<xxxxl") {
        padding: 70px 140px 125px;
      }
      @include media("<xxxl") {
        padding: 70px 15px 125px;
      }
      @include media("<xl") {
        padding: 20px 15px 100px;
      }
      position: relative;
      border-radius: 5px;
      background: rgba(white, 0.9);
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
      .styled__form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      > .btnContainer {
        .btn {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          padding: 8px 24px;
          color: #d46681;
          position: relative;
          overflow: hidden;
          z-index: 1;
          border-radius: 5px;
          text-align: justify;
          width: 700px;
          @include media("<xxl") {
            width: 600px;
          }
          @include media("<xl") {
            width: 260px;
          }
          height: 65px;
          font-family: 'Dosis';
          font-weight: 700;
          font-size: 15.9987px;
          line-height: 24px;
          border: 1px solid #d46681;
          filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: rgba(212, 102, 129, 0.2);
            transition: all 0.5s;
            z-index: -1;
          }
          &:hover {
            &:before {
              width: 100%;
            }
          }
          .btnIcon {
            margin-right: 12px;
          }
        }
      }
      .input__card {
        display: flex;
        flex-wrap: wrap;
        .client__info {
          padding: 30px;
          .description__text {
            margin-bottom: 15px;
            font-size: 25px;
          }
          .client__inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .signed-input {
              margin-bottom: 20px;
              .textfield__settings {
                @include media("<ssm") {
                  max-width: 260px;
                }
              }
            }
          }
        }
      }
      .button__submit {
        display: inline-block;
        padding: 8px 24px;
        background-color: #9E9D9E;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-family: 'Dosis';
        font-style: normal;
        font-weight: 700;
        font-size: 15.9987px;
        line-height: 24px;
        text-align: center;
        text-decoration: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        transition: background-color 0.3s ease;
        margin-top: 68px;
      }
      .button__submit:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .button__submit-ready {
        background-color: #D46681;
      }
    }
  }
}
