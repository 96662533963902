.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 2px;
  width: 100%;
  font-family: 'Dosis';
}

.sign {
  width: 100%;
  text-align: start;
  height: 24px;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 15.9987px;
  line-height: 24px;
  color: #0B2566;
}

.select-field {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid #0B2566;
  border-radius: 5px;
  height: 48px;
}

.select-field.error {
  border-color: #C10E39;
}

.select-field select {
  width: 100%;
  flex: 1;
  border: none;
  background-color: transparent;
  appearance: none;
  font-weight: 500;
  font-size: 15.9987px;
}

.placeholder {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 15.9987px;
  line-height: 24px;
}

.select-field.error select::placeholder {
  color: #C10E39;
}
