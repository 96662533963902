.DetailArticle.card {
    padding: 40px 80px 32px !important;
    .keywords {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: row;
        margin-left: -4px;
        margin-right: -4px;
        > span {
            position: relative;
            padding: 4px 8px;
            background-color: #3a58a1;
            color: white;
            border-radius: 2px;
            margin: 4px 4px 0px;
            &:nth-child(2) {
                margin-left: 6px;
                &::before {
                    content: "";
                    position: absolute;
                    opacity: 0.45;
                    top: 0px;
                    left: -6px;
                    background-color: #3a58a1;
                    border: 0.75px solid #3a58a1;
                    height: 26px;
                    width: 1px;
                }
            }
        }
    }

    .conclusion {
        color: #2e2e2e;
    }
    .research-population {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .btn {
        margin: 0 2px;
        display: inline-flex;
        align-items: center;
        width: fit-content;
        padding: 8px 24px;
        border-radius: 50px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
        background: #d46681;
        color: white;
        > span {
            margin-right: 4px;
        }
    }

    .relevant-links {
        margin-top: 16px;
        > :first-child {
            margin-bottom: 8px;
        }
    }
}
