.DiseaseSymptomInput {
    z-index: 20;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    > * {
        box-sizing: border-box;
        margin-left: 12px !important;
        margin-right: 12px !important;
        flex: 1;
    }
    :first-child {
        margin-left: 0px !important;
    }
    :last-child {
        margin-right: 0px !important;
    }
}
