ul.pagination {
    list-style-type: none;
    margin: 32px 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    > li {
        $p: &;
        display: inline-block;
        &.previous {
            margin-right: 24px;
        }
        &.next {
            margin-left: 24px;
        }
        > .btn {
            width: 32px;
            height: 32px;
            border: 1px solid #3a58a1;
            border-radius: 2px;
            box-sizing: border-box;
            margin: 0px 4px;
            &.btn-enabled {
                color: #3a58a1;
                background-color: white;
            }
            &.btn-disabled {
                color: white;
                background-color: #9e9d9e;
            }
            &.btn-active {
                color: white;
                background-color: #3a58a1;
            }
        }
        > .link {
            padding: none;
            background: none;
            border: none;
            box-shadow: none;
            vertical-align: middle;
            box-sizing: border-box;
            height: 32px;
            color: #3a58a1;
            > svg {
                vertical-align: middle;
            }
            &.disabled {
                opacity: 0.45;
                text-decoration: none;
            }
            text-decoration: underline;
        }
    }
}
