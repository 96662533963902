.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 2px;
  width: 100%;
  height: 74px;
  left: 32px;
  top: 176px;
}

.innerIcon {
  margin-left: 15px;
  position: absolute;
  margin-top: -32px;
  display: block;
}

.label {
  width: 100%;
  text-align: start;
  height: 24px;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 15.9987px;
  line-height: 24px;
  color: #0B2566;
}

.textfield {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  width: 365px;
  height: 48px;
  border: 1px solid #0B2566;
  border-radius: 5px;
  &.error {
    border-color: #C10E39;
  }
}

.textfield::placeholder {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 15.9987px;
  line-height: 24px;
  color: #8E99B7;
  &.error {
    color: #C10E39;
  }
}

.textfield.error::placeholder {
  color: #C10E39;
}


.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 4px;
  height: 24px;
  left: 32px;
  top: 254px;
}

.checkmark {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #D46681;
  border-radius: 2px;
}

.checkbox-label {
  /* Label styles */
  width: 100%;
  height: 24px;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 15.9987px;
  line-height: 24px;
  white-space: nowrap;
  color: #0B2566;
}

.checked .checkmark {
  background: #D46681; /* Change background color for checked state */
}

.checked .checkmark::after {
  content: '';
  width: 5px;
  height: 10px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  left: 3px;
  top: 2px;
  margin-left: 4px;
}

