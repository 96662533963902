body {
    margin: 0;

    > div#root {
        box-sizing: border-box;
        padding: 128px 0px 56px;
        min-height: 100vh;
        height: 100%;
        /* gradient/fluffy blue */
        background: linear-gradient(135deg, rgba(58, 88, 161, 0.6), rgba(216, 129, 150, 0.48));
    }
}

h1 {
    color: #0b2566;
    size: 40px;
    line-height: 48px;
    font-weight: 800;
    font-family: "Dosis", sans-serif;
    margin: 0px;
}

h2 {
    color: #0b2566;
    size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
    margin: 0px;
}

h3 {
    text-decoration: none;
    color: #0b2566;
    size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
    margin: 0px;
}

.body {
    text-decoration: none;
    font-family: "Dosis", sans-serif;
    margin: 0px;
    &.body-bold-big {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
    &.body-big {
        color: #0b2566;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }
    &.body-normal {
        color: #0b2566;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
    &.body-small {
        color: #2e2e2e;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }
    &.body-bold-small {
        color: #0b2566;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
    }
    &.disabled {
        color: #8e99b7;
    }
}

.mark {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    height: fit-content;
    position: relative;
    margin-top: 0px;
    cursor: pointer;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            ~ {
                span {
                    &.check {
                        background-color: #d46681;
                    }
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    span {
        max-height: 12px;
        max-width: 12px;
        min-height: 12px;
        min-width: 12px;
        background-color: white;
        margin-right: 8px !important;
        border: 1px solid #d46681;
        border-radius: 6px;
        &.check:after {
            content: "";
            position: relative;
            display: none;
            left: 4.5px;
            top: 2.5px;
            width: 2.5px;
            height: 5.5px;
            border: solid white;
            border-width: 0 1.5px 1.5px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &.dot:after {
            content: "";
            position: relative;
            display: none;
            left: 2px;
            top: 2px;
            border-radius: 8px;
            background-color: #d46681;
            width: 8px;
            height: 8px;
        }
    }
    p.body-normal {
        margin-bottom: 2px !important;
    }
    &:hover {
        input {
            ~ {
                span {
                    &.check,
                    &.dot {
                        background-color: #ccc;
                    }
                }
            }
        }
    }
}

.slider {
    display: flex;
    flex-direction: column;
    > .slider-wrapper {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
            min-width: 100px;
        }
        > .rc-slider {
            box-sizing: border-box;
            margin: 0px 16px;
            padding: 0px;
            display: flex;
            align-items: center;
            > .rc-slider-rail {
                background-color: #8e99b7;
                opacity: 0.45;
                height: 8px;
            }
            > .rc-slider-track {
                background-color: #3a58a1;
                height: 8px;
            }
            > .rc-slider-handle {
                background-color: #0b2566;
                border: none;
                opacity: 1;
            }
            > * {
                margin-top: 0px;
            }
        }
    }
}
