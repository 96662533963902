// ErrorBanner.scss

.error-banner {
  margin-bottom: 28px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 100%; // Adjust width to 100%
  max-width: 816px; // Add max-width for responsiveness
  background: #F8E7EB; /* tertiary/strong light pink | light */
  border: 1px solid #EF88A1; /* tertiary/light autumn pink */
  border-radius: 5px;
}

.error-message-icon {
  width: 24px;
  height: 24px;
  fill: #C10E39; /* tertiary/autumn pink */
}

.error-message-text {
  text-align: left;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  font-size: 15.9987px;
  line-height: 24px;
  color: #C10E39; /* tertiary/autumn pink */
}

.error-message-header {
  font-weight: bold;
}

.error-message-description {
  font-weight: normal;
}
