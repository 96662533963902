.language-picker {
  font-family: "Dosis", sans-serif;
  box-sizing: border-box;
  color: white;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 150px;

  .current-language {
    display: flex;
    align-items: center;
    padding: 5px;
    img {
      margin-right: 5px;
    }
  }

  .language-dropdown {
    color: black;
    position: absolute;
    top: 100%; /* Змінено з calc(100% + 5px) */
    left: 0;
    z-index: 1;
    display: none;
    padding: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s, visibility 0s linear 0.3s; /* Додано visibility */

    &.open {
      display: block;
      opacity: 1;
      transform: translateY(0);
      visibility: visible; /* Додано visibility */
    }

    li {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      img {
        margin-right: 5px;
      }
    }
  }
}

.language-picker:hover .language-dropdown {
  display: block;
}
