@import "../../media-query-helper.scss";

#detailpage {
    &.detail {
        min-height: calc(100vh - 184px);
        @include media(">=xl") {
            padding: 0px 160px;
        }
        @include media("<xl") {
            padding: 0px 40px;
        }
        display: flex;
        flex-direction: column;
    }

    p#year {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    p#links {
        margin-top: 4px;
        margin-bottom: 8px;
    }

    h2 {
        margin-bottom: 16px;
    }

    .card {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        border-radius: 5px;
        background: rgba(white, 0.9);
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
        margin-bottom: 56px;
        padding: 24px 72px 44px;
        > .back-button {
            position: absolute;
            display: block;
            z-index: 10;
            top: 84px;
            left: 50px;
            > svg > path {
                fill: #0b2566;
            }
            margin-right: -40px;
            margin-top: -44px;
        }
        > div {
            flex-basis: 0;
            display: flex;
            flex-direction: column;
            &:nth-child(2) {
                flex-grow: 5;
                padding-right: 44px;
                border-right: 0.75px solid #8e99b7;
            }
            &:nth-child(3) {
                padding-left: 24px;
                flex-grow: 3;
            }
        }
    }
}
