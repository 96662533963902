@import "../../media-query-helper.scss";

#explanationpage {
    &.explanation {
        min-height: calc(100vh - 184px);
        @include media(">=xl") {
            padding: 0px 160px;
        }
        @include media("<xl") {
            padding: 0px 40px;
        }
        display: flex;
        flex-direction: column;
    }
    .card {
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;
        background: rgba(white, 0.9);
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        padding: 64px 240px 100px;
        > .title {
            text-align: center;
            margin-bottom: 32px;
            > svg > path {
                fill: #3a58a1;
            }
        }
        > ul {
            margin-top: 0px;
        }
    }
}
