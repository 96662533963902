@import "../../media-query-helper.scss";

#searchpage {
    &.search {
        min-height: calc(100vh - 184px);
        @include media(">=xl") {
            padding: 0px 160px;
        }
        @include media("<xl") {
            padding: 0px 40px;
        }
        display: flex;
        flex-direction: column;
    }

    h3 {
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }

    .card {
        box-sizing: border-box;
        min-height: 652px;
        display: flex;
        flex-direction: column;
        padding: 121px 240px 125px;
        @include media("<xxl") {
            padding: 121px 140px 125px;
        }
        @include media("<xl") {
            padding: 121px 40px 125px;
        }
        position: relative;
        border-radius: 5px;
        background: rgba(white, 0.9);
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
        > .title {
            box-sizing: border-box;
            padding: 0px;
            width: 100%;
            position: relative;
            > svg > path {
                fill: #3a58a1;
            }
        }
        > .disease-symptom-wrapper {
            box-sizing: border-box;
            padding: 0px;
            margin-top: 40px;
            width: 100%;
            position: relative;
        }

        > .lifestyle-intervention-wrapper {
            margin-top: 24px;
            box-sizing: border-box;
            padding: 0px;
            width: 100%;
            position: relative;
        }

        > .btn-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            position: relative;
            margin-top: 24px;
            > .btn {
                padding: 8px 24px;
                border-radius: 50px;
                color: white;
                text-align: center;
                width: 48px;
                height: 24px;
                &.btn-enabled {
                    background-color: #d46681;
                }
                &.btn-disabled {
                    background-color: #9e9d9e;
                }
            }
        }
    }
}
