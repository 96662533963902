@import "../../../media-query-helper";

.mainLoginPinPage{
  text-align: -webkit-center;
  margin-top: calc(100vh - 110vh);
}

#loginpinpage {
  &.search {
    min-height: calc(100vh - 184px);
    justify-content: center;
    align-items: center;
    @include media(">=xl") {
      padding: 0px 160px;
    }
    @include media("<xl") {
      padding: 0px 40px;
    }
    display: flex;
    flex-direction: column;
  }

  .card {
    font-family: "Dosis", sans-serif;
    box-sizing: border-box;
    min-height: 368px;
    width: 429px;
    display: flex;
    flex-direction: column;
    padding: 40px 32px 40px;
    position: relative;
    border-radius: 5px;
    background: rgba(white, 0.9);
    align-items: center;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);

    > .title {
      font-weight: bold;
      color: #0B2566;
      font-size: 32px;
      box-sizing: border-box;
      padding: 0px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      > svg > path {
        fill: #3a58a1;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 8px;
      text-align: center;
      margin: 0;
      margin-bottom: 32px;
      > p {
        margin: 0;
      }
    }
    .button {
      display: inline-block;
      padding: 8px 24px;
      background-color: #9E9D9E;
      color: #FFFFFF;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-family: 'Dosis';
      font-style: normal;
      font-weight: 700;
      font-size: x-large;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      transition: background-color 0.3s ease;
      margin-top: 22px    ;
    }
    .button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .button-ready {
      background-color: #D46681;
    }
    .last-message {
      margin-top: 40px;
    }
  }
}
