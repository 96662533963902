@import "../../../media-query-helper.scss";

#settingsPage {
  &.settings {
    min-height: calc(100vh - 184px);
    @include media(">=xl") {
      padding: 0px 160px;
    }
    @include media("<xl") {
      padding: 0px 40px;
    }
    display: flex;
    flex-direction: column;

    .card {
      box-sizing: border-box;
      min-height: 652px;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 70px 240px 125px;
      @include media("<xxl") {
        padding: 70px 140px 125px;
      }
      @include media("<xl") {
        padding: 70px 40px 125px;
      }
      position: relative;
      border-radius: 5px;
      background: rgba(white, 0.9);
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);

      > .title {
        margin-bottom: 50px;
        margin-top: 50px;
        box-sizing: border-box;
        padding: 0px;
        width: 100%;
        position: relative;
        > svg > path {
          fill: #3a58a1;
        }
      }
      > .btnContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        .btn {
          max-width: calc(44% - 10px);
          flex: 1 0 calc(50% - 10px);
          text-decoration: none;
          margin: 5px;
          display: flex;
          align-items: center;
          padding: 8px 24px;
          color: #d46681;
          position: relative;
          overflow: hidden;
          z-index: 1;
          border-radius: 5px;
          text-align: justify;
          width: 300px;
          @include media("<xxl") {
            width: 600px;
          }
          @include media("<xl") {
            width: 260px;
          }
          height: 65px;
          font-family: 'Dosis';
          font-weight: 700;
          font-size: 15.9987px;
          line-height: 24px;
          border: 1px solid #d46681;
          filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: rgba(212, 102, 129, 0.2);
            transition: all 0.5s;
            z-index: -1;
          }
          &:hover {
            &:before {
              width: 100%;
            }
          }
          .btnIcon {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
