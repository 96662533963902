/* Custom style for changing separator  */
ol.breadcrumb {
    list-style-type: none;
    margin: -45px 0px 21px;
    padding: 0;
    > li.breadcrumb-item {
        display: inline;
        color: white;
        + li.breadcrumb-item::before {
            content: " > ";
        }
        > a {
            color: white;
            text-decoration: underline;
        }
    }
}
