@import "../../media-query-helper.scss";

#resultpage {
    &.result {
        min-height: calc(100vh - 184px);
        @include media(">=xl") {
            padding: 0px 160px;
        }
        @include media("<xl") {
            padding: 0px 40px;
        }
        display: flex;
        flex-direction: column;
        &.wrapper {
            justify-content: center;
            align-items: center;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        &:nth-child(2) {
            margin-top: 56px;
        }
        .card {
            padding: 16px;
            .no-results {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: fit-content;
                > {
                    flex: none;
                    h3 {
                        width: fit-content;
                    }
                }
            }
            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .select {
                    width: 250px;
                    > div:nth-child(3) {
                        border: none;
                        box-shadow: none;
                    }
                    &:focus-within {
                        > div:nth-child(3) {
                            border-color: #0b2566;
                            border-radius: 4px 4px 0px 0px;
                            border-style: solid;
                            border-width: 1px;
                        }
                    }

                    > div {
                        border: none;
                        background: none;
                        > div > div {
                            color: #0b2566;
                        }
                        &:nth-child(3) > div {
                            &:first-child {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                padding: 0px;
                                > div {
                                    flex: 0 0 auto;
                                    &:last-child {
                                        flex-direction: row-reverse;
                                        justify-content: flex-end;
                                    }
                                    > input {
                                        text-align: end !important;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                > span {
                                    display: none;
                                }
                                > div {
                                    padding: 0px;
                                }
                            }
                        }
                        &:nth-child(4) {
                            margin: 0px;
                            > div {
                                padding: 0px;
                                margin-top: -1px;
                                border-color: #0b2566;
                                border-style: solid;
                                border-width: 1px;
                                border-radius: 0px 0px 4px 4px;
                                > div {
                                    background-color: white;
                                    &:first-child {
                                        margin-top: -1px;
                                    }

                                    border-top: #0b2566 solid 1px;
                                    &:hover {
                                        background: #d46681;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    h1 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 8px;
        text-transform: capitalize;
    }

    p#filters {
        text-transform: capitalize;
    }

    .card {
        height: fit-content;
        position: relative;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        background: rgba(white, 0.9);
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        padding: 24px 72px;
        &:first-child {
            background: rgba(white, 1);
            box-sizing: border-box;
            position: fixed;
            top: 65px;
            left: 0px;
            z-index: 50;
            border-radius: 0px 0px 5px 5px;
            margin-bottom: 84px;
            > .breadcrumb {
                margin: 0px 0px 0px -42px;
                > li {
                    color: #0b2566;
                    > a {
                        color: #0b2566;
                    }
                }
            }
            .back-button {
                top: 87px;
                @include media(">=xl") {
                    left: 163px;
                }
                @include media("<xl") {
                    left: 43px;
                }
            }
            opacity: 1;

            @include media(">=xl") {
                padding: 22px 202px;
            }
            @include media("<xl") {
                padding: 22px 82px;
            }
            width: 100vw;
            > div > div {
                h2 {
                    margin-right: 36px;
                    text-transform: capitalize;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        > .back-button {
            position: absolute;
            display: block;
            z-index: 10;
            top: 69px;
            left: 18px;
            > svg > path {
                fill: #0b2566;
            }
            margin-right: -56px;
            margin-top: -44px;
        }
        .btn-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0px;
            > .btn {
                width: fit-content;
                padding: 8px 24px;
                border-radius: 50px;
                color: white;
                background-color: #d46681;
            }
        }
    }
}
