.article-row {
    background-color: rgba(#0b2566, 0.05);
    box-shadow: none;
    border: 1px solid rgba(#0b2566, 0.15);
    padding: 16px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    margin: 13px 0px;
    &:first-of-type {
        margin-top: 0px;
    }
    &:last-of-type {
        margin-bottom: 0px;
    }
    border-radius: 5px;
    > :nth-child(1) {
        flex-grow: 5;
        flex-basis: 0;
        padding-right: 24px;
        border-right: 0.75px solid #8e99b7;
        > h3 {
            margin-bottom: 16px;
        }
        > .keywords {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-direction: row;
            margin-top: -8px;
            margin-left: -4px;
            margin-right: -4px;
            > span {
                padding: 4px 8px;
                background-color: #3a58a1;
                color: white;
                border-radius: 2px;
                margin: 4px 4px 0px;
            }
        }
        > .research-population {
            margin-top: 16px;
            color: #2e2e2e;
        }
    }
    > :nth-child(2) {
        padding-left: 24px;
        flex-grow: 3;
        flex-basis: 0;
    }
}

.link-unstyled {
    &,
    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:active:hover {
        font-style: inherit;
        color: inherit;
        font-size: inherit;
        text-decoration: none;
        font-variant: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: inherit;
        outline: inherit;
        box-shadow: inherit;
        padding: inherit;
        vertical-align: inherit;
    }
    &:hover {
        cursor: pointer;
    }
}
