@import "../../media-query-helper.scss";

.navbar {
    @include media(">=xl") {
        padding-left: 160px;
        padding-right: 160px;
    }
    @include media("<xl") {
        padding-left: 40px;
        padding-right: 40px;
    }
    position: fixed;
    background: #3a58a1;
    display: flex;
    flex-direction: row;
    margin-top: -128px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    height: 72px;
    align-items: center;
    z-index: 100;

    > .navbar-brand {
        display: block;
        padding: 6.2px 0px;
        text-align: center;
        margin-right: 40px;
        > img {
            filter: invert(1);
        }
    }
    > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        > ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            > li > {
                div.menu-flags {
                    > button {
                        border: none;
                        > span > span:nth-child(2) {
                            color: white;
                        }
                        &[aria-expanded="false"]::after {
                            border-top: 5px solid white;
                        }
                        &[aria-expanded="true"]::after {
                            border-bottom: 5px solid white;
                        }
                    }
                    > ul > li {
                        > span > span:nth-child(2) {
                            color: #0b2566;
                        }
                    }
                }
                a {
                    > svg {
                        margin-bottom: -4px;
                        > path {
                            fill: white;
                        }
                    }
                    &.nav-link {
                        color: white;
                    }
                    text-align: center;
                    padding: 14px 16px;
                    text-decoration: none;
                }
                a:hover {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }
}
