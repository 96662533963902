.LifestyleInterventionButton {
    position: relative;
    box-sizing: content-box;
    background-color: white;
    flex: 1;
    min-width: 138px;
    max-height: fit-content;
    height: fit-content;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    border: 1px solid #8e99b7;
    border-radius: 2px;
    margin: 0px 4px 8px;
    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
    .collapsible {
        padding: 8px;
        background-color: white;
        &.disabled {
            background-color: #e2e5ed;
        }
        border: none;
        cursor: pointer;
        min-height: 92px;
        max-height: fit-content;
        z-index: 10;
        > p {
            justify-content: center;
            display: flex;
            align-items: center;
            color: #8e99b7;
            > span {
                margin-top: 2px;
                margin-bottom: -2px;
                display: inline-block;
                font-size: 14px;
            }
            &:nth-child(3) {
                display: none;
            }
        }
        ~ .content {
            box-sizing: border-box;
            position: absolute;
            width: calc(100% + 2px);
            top: 92px;
            left: -1px;
            margin-top: 2px;
            background-color: white;
            max-height: 0px;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            z-index: 1000;
            > .lifestyle-intervention-selector {
                width: calc(100% - 18px);
                display: flex;
                flex-direction: row;
                align-items: center;
                > p {
                    margin-bottom: 0px !important;
                }
                > input {
                    display: none;
                }
                > span {
                    color: #d46681;
                    font-size: 20px;
                    margin-right: 4px;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            > .input-other-lifestyle {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
                box-sizing: border-box;
                margin-top: 0px;
                margin-left: 18px;
                width: calc(100% - 18px);
                height: 32px;
                padding: 8px 0px 8px 12px;
                z-index: 30;
                border-radius: 5px;
                border-color: #8e99b7;
                background-color: #e2e5ed;
                border-width: 1px;
                border-style: solid;
                &.enabled {
                    border-color: #0b2566;
                    background-color: white;
                }
                input {
                    box-sizing: border-box;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding: 0px;
                    color: #0b2566;
                    background: none;
                    &::placeholder,
                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder {
                        color: #8e99b7;
                        opacity: 1;
                    }
                }
                button {
                    display: flex;
                    padding: 0;
                    margin: 0;
                    border: none;
                    background: none;
                    align-items: flex-start;
                    > span.material-icons {
                        color: #9e9d9e;
                        &.enabled {
                            color: #d46681;
                        }
                    }
                }
            }
        }
        &.active {
            background-color: #ffffff;
            > p {
                color: #0b2566;
            }
            &::after {
                content: "";
                width: calc(100% - 16px);
                position: absolute;
                left: 8px;
                top: 92px;
                background-color: #8e99b7;
                min-height: 0.75px;
                z-index: 30;
            }
            & ~ .content {
                max-height: fit-content;
                padding: 7px 7px 7px;
                border: 1px solid #8e99b7;
                border-top: none;
            }
        }
        &.has-selection {
            &:not(.active) {
                background-color: #3a58a1;
                > p {
                    color: #ffffff;
                }
                > p {
                    &:nth-child(2) {
                        margin-bottom: 16px !important;
                    }
                    &:nth-child(3) {
                        display: block;
                    }
                }
                &::after {
                    content: "";
                    width: calc(100% - 16px);
                    position: absolute;
                    left: 8px;
                    top: 92px;
                    background-color: #8e99b7;
                    min-height: 0.75px;
                    z-index: 30;
                }
            }
        }
    }
}
