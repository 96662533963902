.rating-group {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    .total {
        margin-top: 0px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > span > span {
            width: 23px !important;
            margin: 0 1.5px;
            > span {
                width: 23px;
                box-sizing: content-box;
                right: 0px;
                > .material-icons {
                    margin: -0px -4.5px;
                    font-size: 32px;
                    width: 32px !important;
                    color: #0b2566;
                }
            }
        }
    }

    p {
        color: #2e2e2e !important;
    }

    .industry-payed {
        margin-top: 8px;
        padding: 8px;
        background-color: #e9b6c3;
        display: flex;
        flex-direction: row;
        align-items: center;
        > span {
            margin-right: 8px;
            font-size: 16px;
            box-sizing: border-box;
            color: #2e2e2e;
        }
    }

    .indicator {
        display: flex;
        flex-direction: row;
        margin: 4px 0px;
        > span:first-child {
            margin-right: 8px;
            font-size: 16px;
            box-sizing: border-box;
            align-self: center;
            &.positive {
                color: #8dbb6c;
            }
            &.negative {
                color: #d46681;
            }
        }
    }
}
