.FilterSection {
    width: fit-content;
    height: fit-content;
    background-color: white;
    margin-right: 24px;
    opacity: 0.9;
    box-shadow: 0px 0px 24px rgba(46, 46, 46, 0.05);
    border-radius: 5px;
    padding: 16px;
    > h3 {
        line-height: 24px;
        size: 16px;
    }
    > p {
        margin-top: 16px;
    }
    .mark {
        margin-top: 8px;
        > p {
            font-weight: 600;
        }
    }
    #slider-age,
    #slider-publication-year {
        display: flex;
        justify-content: space-between;
    }
    #slider-age-label,
    #slider-publication-year-label {
        > p {
            width: max-content;
            color: #8e99b7;
        }
    }
}
